




























import { authorizedRequest } from '@/api/api'
import { API_ROUTES } from '@/api/apiRoutes'
import _ from 'lodash'
import AddPortfolioButton from '@/components/addPortfolioButton/AddPortfolioButton.vue'
import mixins from 'vue-typed-mixins'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import UpdatePortfolioButton from '@/components/updatePortfolioButton/UpdatePortfolioButton.vue'

export default mixins(numberFormatMixin).extend({
    components: {
        AddPortfolioButton,
        UpdatePortfolioButton,
    },
    data() {
        return {
            portfolios: [],
        }
    },
    computed: {
        totalSize(): number {
            return _.sumBy(this.portfolios, 'size')
        },
    },
    methods: {
        loadPortfolios(): void {
            authorizedRequest().get(API_ROUTES.PORTFOLIOS.GET)
                .then((response) => {
                    this.portfolios = response.data
                })
                .catch(e => console.error(e))
        },
        removePortfolio(portfolio: any) {
            authorizedRequest().delete(API_ROUTES.PORTFOLIOS.DELETE + portfolio.id)
                .then((response) => {
                    return this.reload()
                })
                .catch((e) => console.error(e))
        },
        reload(): void {
            this.loadPortfolios()
        },
    },
    mounted(): void {
        this.loadPortfolios()
    },
})
