




















import Vue from 'vue'
import { API_ROUTES } from '@/api/apiRoutes'
import { authorizedRequest } from '@/api/api'

export default Vue.extend({
    data() {
        return {
            showModal: false,
            title: '',
            description: '',
        }
    },
    methods: {
        createPortfolio(): void {
            authorizedRequest().post(API_ROUTES.PORTFOLIOS.ADD, {
                title: this.title,
                description: this.description,
            })
                .then(() => {
                    this.showModal = false
                    this.$emit('create')
                })
                .catch((e) => console.error(e))
        },
    },
})
