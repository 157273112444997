














import Vue from 'vue'
import { authorizedRequest } from '@/api/api'
import { API_ROUTES } from '@/api/apiRoutes'

export default Vue.extend({
    props: {
        value: {
            type: Boolean,
        },
        portfolio: {
            type: Object,
        },
    },
    data() {
        return {
            title: '',
            description: '',
        }
    },
    computed: {
        showModal: {
            get(): boolean {
                return this.value
            },
            set(value: boolean) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        transaction: {
            handler() {
                this.refreshData()
            },
            deep: true,
        },
    },
    methods: {
        refreshData(): void {
            this.title = this.portfolio.title
        },
        updatePortfolio() {
            return authorizedRequest().post(API_ROUTES.PORTFOLIOS.UPDATE + this.portfolio.id, {
                title: this.title,
                description: this.description,
            })
                .then((response) => {
                    this.showModal = false
                    this.$emit('update')
                })
                .catch((e) => console.error(e))
        },
    },
    mounted() {
        this.refreshData()
    },
})
