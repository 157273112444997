














import Vue from 'vue'
import UpdatePortfolioModal from '@/components/updatePortfolioButton/UpdatePortfolioModal.vue'

export default Vue.extend({
    components: {
        UpdatePortfolioModal,
    },
    props: {
        portfolio: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        onUpdate() {
            this.$emit('update')
        },
    },
})
